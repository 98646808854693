footer {
    display: flex;
    flex-direction: column;
    height: 45vh;
    margin-top: 200px;
    background-color: rgb(255, 255, 255);
    border-top: solid 10px #E4232C;
    position: relative;
    overflow: hidden;
}



#footLogo {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    height: 45vh;
    justify-content: center;
}

#logoFooter {
    width: 50%;
    height: 30%;
    margin-top: 100px;

}

#socialmedia {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 40px;
    margin-top: 160px
}

.copy {}





#termsImg {
    width: 16px;
    height: 16px;
    color: red;
    margin-top: 1px;
}

#bootm {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.terms {
    text-align: center;
    display: flex
}





.termsText:hover {
    cursor: pointer;

}

@media screen and (max-width: 480px) and (min-width: 320px) {
    footer {
        margin-top: 40px;
    }

    #footLogo {
        flex-direction: column;
        margin-bottom: 10px;
        margin-top: 10px;

    }

    #logoFooter {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    #socialmedia {
        margin-top: 20px;
    }




}