#about {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    place-items: center;
    width: 100%;
    font-size: 20px;
    margin-top: 55px;

}

#title {
    font-size: 50px;
    font-weight: normal;
    color: #E4232C;
    margin-bottom: 55px
}

#Aboutdescription {


    font-size: 30px;
    margin-left: 160px;
    margin-right: 160px;
}

@media screen and (max-width: 480px) and (min-width: 320px) {



    #about {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px !important;


        width: 100%;
        font-size: 20px;
        margin-top: 10px;

    }

    #title {
        font-size: 50px !important;
        color: #E4232C;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
        font-weight: normal;
    }

    #Aboutdescription {
        font-size: 20px;
        justify-content: start;
        margin-top: 10px !important;
        margin-right: 12% !important;
        margin-left: 12% !important;
    }



}

@media screen and (max-width: 1300px) and (min-width: 500px) {



    #about {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px !important;


        width: 100%;
        font-size: 20px;
        margin-top: 10px;

    }

    #title {
        font-size: 50px !important;
        color: #E4232C;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
        font-weight: normal;
    }

    #Aboutdescription {
        font-size: 20px;
        justify-content: start;
        margin-top: 10px !important;
        margin-right: 12% !important;
        margin-left: 12% !important;
    }



}