#infinitiSlider {
    height: 130px;
    width: 98%;
    margin-top: 100px;

    background-color: rgba(255, 255, 255, 0);

}

.sliderBox {
    margin-left: 10px;

}

.sliderContent {
    height: 120px;
    object-fit: contain;
    width: 80%;
    border-radius: 20px;
    margin-left: 0px;
}

@media screen and (max-width: 480px) and (min-width: 320px) {


    #infinitiSlider {
        margin-top: 20px;

    }
}