nav {
    width: 100%;
    height: 90px;
    background-color: #E4232C;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 9999;
    left: 0;
    margin: 0;
}


ul {
    display: flex;
    flex-direction: row;
}

li {
    list-style-type: none;
    display: inline-block;
    margin: 50px;
    color: white;
    cursor: pointer;
}

#loginR {
    background-color: #E4232C;
    color: white;
    border-radius: 60px;
    border-width: 2px;

    border-color: white;
    height: 70%;
    margin-right: 10px;

}

#HUbLogo {
    height: 85px;
    margin: 10px;
}

@keyframes sway {
    0% {
        transform: rotate(8deg);
    }

    50% {
        transform: rotate(-8deg);
    }

    100% {
        transform: rotate(8deg);
    }
}




.object {
    animation: sway 2.4s infinite;
    animation-timing-function: ease-in-out;
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    transform-origin: top;
}

.object-shape {
    width: 100px;
    height: 100px;
    background-color: #E4232C;
    margin: 0 auto;
    margin-right: -50px;
    border-radius: 50px;
    border: dotted;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.object-rope {
    height: 250px;
    width: 1px;
    background-color: white;
    content: "";
    margin-left: 50px;

}

#login {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 17px;
    margin-left: 9%;
    margin-right: 15px;
    margin-top: 31%;
}

#login:hover {

    cursor: pointer;

}

#HUbLogoPhone {
    display: none;
}

/*Media Query Header*/
@media screen and (max-width: 480px) and (min-width: 320px) {


    li {
        font-size: 15px;
        margin: 1px !important;

    }

    #HUbLogoPhone {
        display: block;
        height: 100%;
    }

    #HUbLogo {
        display: none;
    }


    ul {
        margin-right: 40px !important;
        background-color: #E4232C;
        width: 100%;
        left: 0;
        margin-top: 0 !important;
        justify-content: space-evenly;
    }

    #ul2 {
        justify-content: center
    }


    nav {
        height: 100px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 9999;
        left: 0;
        margin: 0;

    }

    .object-shape {
        width: 70px;
        height: 70px;
        background-color: #E4232C;
        margin: 0 !important;
        border-radius: 50px;
        border: dotted;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .object-rope {
        height: 100px;
        width: 2px;
        background-color: rgb(0, 0, 0);
        content: "";
        margin-left: 35px;


    }

    .object {
        margin-left: 250px !important;
        animation: sway 2s infinite;
        margin-top: 0 !important;
        animation-timing-function: ease-in-out;
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        transform-origin: top;
    }

    #login {
        font-size: 15px;
    }

}

@media screen and (max-width: 1300px) and (min-width: 500px) {




    li {
        font-size: 15px;
        margin: 1px !important;

    }

    #HUbLogoPhone {
        display: block;
        height: 100%;
    }

    #HUbLogo {
        display: none;
    }


    ul {
        margin-right: 40px !important;
        background-color: #E4232C;
        width: 100%;
        left: 0;
        margin-top: 10px !important;
        justify-content: space-evenly;
    }

    #ul2 {
        justify-content: center
    }


    nav {
        height: 100px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 9999;
        left: 0;
        margin: 0;

    }

    .object-shape {
        width: 70px;
        height: 70px;
        background-color: #E4232C;
        margin: 0 !important;
        border-radius: 50px;
        border: dotted;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .object-rope {
        height: 100px;
        width: 2px;
        background-color: rgb(0, 0, 0);
        content: "";
        margin-left: 35px;


    }

    .object {
        margin-left: 250px !important;
        animation: sway 2s infinite;
        margin-top: 0 !important;
        animation-timing-function: ease-in-out;
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        transform-origin: top;
    }

    #login {
        font-size: 15px;
    }



}