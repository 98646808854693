#Appcontainer {
    width: 100%;
    margin-top: 50px;
    overflow: hidden;

}

#Apptitle {
    font-size: 70px;
    color: #E4232C;
    font-weight: normal;
    text-align: center;

}

#phone {
    height: 550px;
    width: 300px;
    margin-left: 55px;

}

#phoneIosAndroied {
    display: flex;
    flex-direction: row;
    align-items: center;
}



#description {
    font-size: 25px;
    margin-left: 100px;
    margin-right: 100px;
}

#buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;
}

.download {
    width: 100%;

}

#Android {
    width: 30%;
    cursor: pointer;
}

#ios {
    width: 33%;
    cursor: pointer;

}

@media screen and (max-width: 480px) and (min-width: 320px) {

    #Appdescription {
        margin-top: 10px
    }

    /* * { */
    /* margin: auto !important; */
    /* } */

    #phoneIosAndroied {
        margin: auto !important;

    }

    #Apptitle {
        font-size: 50px;
        color: #E4232C;
        margin-top: 10px !important;
        font-weight: normal;
    }

    #description {
        font-size: 15px;
        margin: 10px;
        margin-left: 25px;
        margin-right: 25px;
    }

    #phone {
        height: 200px;
        width: 90px;

        margin-left: 20px;

    }

    #Android {
        margin: auto;
    }

    #ios {
        margin: auto;
    }

    #buttons {
        margin: 10px;
    }
}


@media screen and (max-width: 1300px) and (min-width: 500px) {

    #Appdescription {
        margin-top: 10px
    }

    /* * { */
    /* margin: auto !important; */
    /* } */

    #phoneIosAndroied {
        margin: auto !important;

    }

    #Apptitle {
        font-size: 50px;
        color: #E4232C;
        margin-top: 10px !important;
        font-weight: normal;
    }

    #description {
        font-size: 15px;
        margin: 10px;
        margin-left: 25px;
        margin-right: 25px;
    }

    #phone {
        height: 200px;
        width: 90px;

        margin-left: 20px;

    }

    #Android {
        margin: auto;
    }

    #ios {
        margin: auto;
    }

    #buttons {
        margin: 10px;
    }
}