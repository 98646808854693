#services {
    width: 100%;
    margin-bottom: 100px;
    border-top: 10px solid #E4232C;

}

#servicesTitle {
    font-size: 70px;
    color: #E4232C;
    font-weight: normal;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

#servicesImg {
    width: 100%;
    position: absolute;

}

#servicesDesc {
    position: relative;
    font-size: 25px;
    margin-left: 400px;
    margin-top: 30px;
    margin-right: 100px;
}

#ourbutton {
    position: relative;
    left: 41%;
    width: 280px;
    margin-top: 40px;
    height: 60px;
    text-align: center;
    color: white;
    font-size: 25px;
    border-radius: 20px;
    background-color: #E4232C;
    border: solid 1px red;
    cursor: pointer;
}

#servicesImgStore {
    display: none
}



@media screen and (max-width: 480px) and (min-width: 320px) {

    #servicesTitle {
        font-size: 40px;
        font-weight: normal;
        color: #E4232C;
        margin-bottom: 60px;
        margin-top: 40px;
    }


    #servicesDesc {
        font-size: 15px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;

    }

    #ourbutton {
        width: 200px;
        height: 50px;
        font-size: 15px;
    }

    #servicesflex {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    #servicesImg {
        display: none;
    }

    #servicesImgStore {
        display: block;
        width: 200px;
        height: 200px;
    }

}

@media screen and (max-width: 1300px) and (min-width: 500px) {
    #services {
        width: 100%;
        margin-bottom: 10px;
        border-top: 10px solid #E4232C;

    }

    #servicesTitle {
        font-size: 40px;
        font-weight: normal;
        color: #E4232C;
        margin-bottom: 60px;
        margin-top: 40px;
    }


    #servicesDesc {
        font-size: 15px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 25px;

    }

    #ourbutton {
        width: 200px;
        height: 50px;
        font-size: 15px;
        margin-top: 10px;
    }

    #servicesflex {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    #servicesImg {
        display: none;
    }

    #servicesImgStore {
        display: block;
        width: 200px;
        height: 200px;
    }

}