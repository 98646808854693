#vm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12%;
    margin-top: 100px;
    flex-wrap: wrap;
}

/* Container (START)*/
#v {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 20%;


}

#m {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 20%;

}

#vlaues {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 20%;


}

/* Container (END)*/


/* Titles (START)  */

#vtitle {
    font-size: 50px;
    color: #E4232C;
    font-weight: normal;

}

#mtitle {
    font-size: 50px;
    color: #E4232C;
    font-weight: normal;

}

#valuestitle {
    font-size: 50px;
    color: #E4232C;
    font-weight: normal;
}

/* Titles (END)  */

/************************/

/*Description (SATRT)*/

#vdescription {
    font-size: 25px;
}

#mdescription {
    font-size: 25px;

}

#valuesdescription {
    font-size: 25px;

}

/*Description (END)*/

/************************/

/*Images (START)*/

#vimg {
    width: 400px;
    height: 250px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 20px;

}

#mimg {
    width: 400px;
    height: 250px;
    margin-bottom: 10px;
    margin-top: 10px;

}

#valuesImg {
    width: 400px;
    height: 250px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 20px;

}

/*Images (END)*/



@media screen and (max-width: 480px) and (min-width: 320px) {



    #vm {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10%;
        margin-top: 100px;
        flex-wrap: wrap;
    }

    /* Container (START)*/
    #v {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 40%;


    }

    #m {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 40%;

    }

    #vlaues {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 20%;


    }

    /* Container (END)*/


    /* Titles (START)  */

    #vtitle {
        font-size: 25px;
        color: #E4232C;
        font-weight: normal;

    }

    #mtitle {
        font-size: 25px;
        color: #E4232C;
        font-weight: normal;

    }

    #valuestitle {
        font-size: 25px;
        color: #E4232C;
        width: 150px;
        font-weight: normal;

    }

    /* Titles (END)  */

    /************************/

    /*Description (SATRT)*/

    #vdescription {
        font-size: 20px;
        word-spacing: -7px;




    }

    #mdescription {
        font-size: 20px;

        word-spacing: -7px;


    }

    #valuesdescription {
        font-size: 20px;



    }

    /*Description (END)*/

    /************************/

    /*Images (START)*/

    #vimg {
        width: 190px;
        height: 150px;
        margin-bottom: 10px;
        margin-top: 10px;
        border-radius: 10px;

    }

    #mimg {
        width: 190px;
        height: 150px;
        margin-bottom: 10px;
        margin-top: 10px;
        border-radius: 10px;

    }

    #valuesImg {
        width: 190px;
        height: 150px;
        margin-bottom: 10px;
        margin-top: 10px;
        border-radius: 10px;

    }

    /*Images (END)*/

}

@media screen and (max-width: 1300px) and (min-width: 500px) {




    #vm {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10%;
        width: 100%;
        margin-top: 100px;
        flex-wrap: wrap;
    }

    /* Container (START)*/
    #v {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 40%;


    }

    #m {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 40%;

    }

    #vlaues {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        place-items: center;
        width: 20%;


    }

    /* Container (END)*/


    /* Titles (START)  */

    #vtitle {
        font-size: 25px;
        color: #E4232C;
        font-weight: normal;

    }

    #mtitle {
        font-size: 25px;
        color: #E4232C;
        font-weight: normal;

    }

    #valuestitle {
        font-size: 25px;
        width: 150px;
        color: #E4232C;
        font-weight: normal;

    }

    /* Titles (END)  */

    /************************/

    /*Description (SATRT)*/

    #vdescription {
        font-size: 20px;
        word-spacing: -7px;




    }

    #mdescription {
        font-size: 20px;

        word-spacing: -7px;


    }

    #valuesdescription {
        font-size: 20px;



    }

    /*Description (END)*/

    /************************/

    /*Images (START)*/

    #vimg {
        width: 230px;
        height: 150px;
        margin-bottom: 10px;
        margin-top: 10px;
        border-radius: 10px;

    }

    #mimg {
        width: 230px;
        height: 150px;
        margin-bottom: 10px;
        margin-top: 10px;
        border-radius: 10px;

    }

    #valuesImg {
        width: 230px;
        height: 150px;
        margin-bottom: 10px;
        margin-top: 10px;
        border-radius: 10px;

    }

    /*Images (END)*/


}